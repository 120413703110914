<template>
  <div class="wx-crowd">
    <div class="wx-crowd-title">
      <van-row class="info-title">微信群信息</van-row>
    </div>
    <van-dropdown-menu>
      <van-dropdown-item :title='crowdTitle' v-model="valueCrowd" @change='handelChangeCrowd' :options="optionCrowd"/>
      <van-dropdown-item :title='yearTitle' v-model="valueYear" @change='handelChangeYear' :options="optionYear"/>
      <van-dropdown-item :title='monthTitle' v-model="valueMonth" @change='handelChangeMonth' :options="optionMonth"/>
      <van-dropdown-item :title='dayTitle' v-model="valueDay" @change='handelChangeDay' :options="optionDay"/>
      <van-dropdown-item :title='hourTitle' v-model="valueHour" @change='handelChangeHour' :options="optionHour"/>
    </van-dropdown-menu>
    <van-row>
      <el-table
          :data="tableData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          style="width: 100%">
        <el-table-column
            type="selection"
            min-width="11%">
        </el-table-column>
        <el-table-column
            label="文件名称"
            min-width="45%">
          <template slot-scope="props">
            <div>
              <span>{{ props.row.fileName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="年-月-日-时"
            min-width="30%">
          <template slot-scope="props">
            <div>
              <span>{{ props.row.year }}-{{ props.row.month }}-{{ props.row.day }}-{{ props.row.hour }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            min-width="14%">
          <template slot-scope="scope">
            <el-button @click="downloadFiles(scope.row.fileName)" type="text" size="small">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </van-row>
  </div>
</template>

<script>
import {Toast} from "vant";
import {getWxCrowdList} from '@/api/wxCrowd'
import {getWxCrowdTitle} from '@/api/wxCrowd'
import {getWxYearTitle} from '@/api/wxCrowd'
import {getWxMonthTitle} from '@/api/wxCrowd'
import {getWxDayTitle} from '@/api/wxCrowd'
import {getWxHourTitle} from '@/api/wxCrowd'
import JSZip from "jszip";

export default {
  name: "WxCrowd",
  components: {},
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      crowdTitle: '群名',
      yearTitle: '年',
      monthTitle: '月',
      dayTitle: '日',
      hourTitle: '时',
      valueCrowd: 0,
      valueYear: 0,
      valueMonth: 0,
      valueDay: 0,
      valueHour: 0,
      optionCrowd: [],
      optionYear: [],
      optionMonth: [],
      optionDay: [],
      optionHour: [],
      btn: 'Y'
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getWxCrowdList({
        groupName: this.crowdTitle == '群名' ? '' : this.crowdTitle,
        year: this.yearTitle == '年' ? '' : this.yearTitle,
        month: this.monthTitle == '月' ? '' : this.monthTitle,
        day: this.dayTitle == '日' ? '' : this.dayTitle,
        hour: this.hourTitle == '时' ? '' : this.hourTitle,
        btn: this.btn
      }).then(res => {
        this.btn = "N"
        console.log(res)
        this.tableData = res
        Toast.success('加载成功！');
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败！');
      })
      this.int1()
    },
    int1(){
      this.clearAll();
      getWxCrowdTitle({}).then(res => {
        for (let i = 0; i < res.length; i++) {
          var array = {}
          array.text = res[i].text
          array.value = res[i].val
          this.optionCrowd.push(array)
        }
        console.log(1)
        console.log(this.optionCrowd)
      }).catch(err => {
        console.log(err)
      })
      getWxYearTitle({
        groupName: this.crowdTitle == '群名' ? '' : this.crowdTitle
      }).then(res => {
        for (let i = 0; i < res.length; i++) {
          var array = {}
          array.text = res[i].text
          array.value = res[i].val
          this.optionYear.push(array)
        }
      }).catch(err => {
        console.log(err)
      })
      getWxMonthTitle({
        groupName: this.crowdTitle == '群名' ? '' : this.crowdTitle,
        year: this.yearTitle == '年' ? '' : this.yearTitle
      }).then(res => {
        for (let i = 0; i < res.length; i++) {
          var array = {}
          array.text = res[i].text
          array.value = res[i].val
          this.optionMonth.push(array)
        }
      }).catch(err => {
        console.log(err)
      })
      getWxDayTitle({
        groupName: this.crowdTitle == '群名' ? '' : this.crowdTitle,
        year: this.yearTitle == '年' ? '' : this.yearTitle,
        month: this.monthTitle == '月' ? '' : this.monthTitle
      }).then(res => {
        for (let i = 0; i < res.length; i++) {
          var array = {}
          array.text = res[i].text
          array.value = res[i].val
          this.optionDay.push(array)
        }
      }).catch(err => {
        console.log(err)
      })
      getWxHourTitle({
        groupName: this.crowdTitle == '群名' ? '' : this.crowdTitle,
        year: this.yearTitle == '年' ? '' : this.yearTitle,
        month: this.monthTitle == '月' ? '' : this.monthTitle,
        day: this.dayTitle == '日' ? '' : this.dayTitle
      }).then(res => {
        for (let i = 0; i < res.length; i++) {
          var array = {}
          array.text = res[i].text
          array.value = res[i].val
          this.optionHour.push(array)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onClick() {
      this.init();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    downloadFiles(row) {
      if (this.multipleSelection.length > 0) {
        // 文件列表
        const fileList = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          var array = {}
          array.name = this.multipleSelection[i].fileName
          array.path = 'https://hb-wx-pic.oss-cn-shanghai.aliyuncs.com/'
              + this.multipleSelection[i].groupName1 + '/'
              + this.multipleSelection[i].year + '/'
              + this.multipleSelection[i].month + '/'
              + this.multipleSelection[i].day + '/'
              + this.multipleSelection[i].hour + '/'
              + this.multipleSelection[i].fileName
          fileList.push(array)
        }
        console.log(fileList)
        const zip = new JSZip();
        for (var i = 0; i < fileList.length; i++) {
          zip.file(fileList[i].name, this.fetchBlob(fileList[i].path)); // 支持Promise类型，需要返回数据类型是 String, Blob, ArrayBuffer, etc
        }
        zip.generateAsync({type: "blob"}).then(blob => {
          const url = window.URL.createObjectURL(blob);
          this.downloadFile(url, "微信照片.zip");
        });
      } else {
        this.$message.warning('请至少选择一个文件进行下载！');
      }
    },
    downloadFile(url, fileName) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async fetchBlob(fetchUrl, method = "GET", body = null) {
      const response = await window.fetch(fetchUrl, {method, body});
      const blob = await response.blob();
      return blob;
    },
    handelChangeCrowd(value) {
      console.log(value)
      this.crowdTitle = this.optionCrowd[value].text
      this.yearTitle = '年'
      this.monthTitle = '月'
      this.dayTitle = '日'
      this.hourTitle = '时'
      this.valueYear = 0
      this.valueMonth = 0
      this.valueDay = 0
      this.valueHour = 0
      this.int1()
    },
    handelChangeYear(value) {
      console.log(value)
      this.yearTitle = this.optionYear[value].text
      this.monthTitle = '月'
      this.dayTitle = '日'
      this.hourTitle = '时'
      this.valueMonth = 0
      this.valueDay = 0
      this.valueHour = 0
      this.int1()
    },
    handelChangeMonth(value) {
      console.log(value)
      this.monthTitle = this.optionMonth[value].text
      this.dayTitle = '日'
      this.hourTitle = '时'
      this.valueDay = 0
      this.valueHour = 0
      this.int1()
    },
    handelChangeDay(value) {
      console.log(value)
      this.dayTitle = this.optionDay[value].text
      this.hourTitle = '时'
      this.valueHour = 0
      this.init()
    },
    handelChangeHour(value) {
      console.log(value)
      this.hourTitle = this.optionHour[value].text
      this.init()
    },
    clearAll() {
      this.optionCrowd = []
      this.optionYear = []
      this.optionMonth = []
      this.optionDay = []
      this.optionHour = []

    }
  }
}
</script>

<style scoped>
.wx-crowd {
  background-color: #FFFFFF
}

.wx-crowd .info-title {
  background-color: #318EF5;
  padding: 10px 10px 10px;
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
  font-style: normal;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
}

.wx-crowd .secondRow {
  color: #66b1ff;
  /*padding-left: 10px;*/
}
</style>
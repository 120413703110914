import request from '@/utils/request'

export function getWxCrowdList(formFile) {
    return request({
        url: "/api/ajax/wxCrowd/getWxCrowdList.json",
        method: 'post',
        data: formFile,
    })
}

export function getWxCrowdTitle(formFile) {
    return request({
        url: "/api/ajax/wxCrowd/getWxCrowdTitle.json",
        method: 'post',
        data: formFile,
    })
}

export function getWxYearTitle(formFile) {
    return request({
        url: "/api/ajax/wxCrowd/getWxYearTitle.json",
        method: 'post',
        data: formFile,
    })
}

export function getWxMonthTitle(formFile) {
    return request({
        url: "/api/ajax/wxCrowd/getWxMonthTitle.json",
        method: 'post',
        data: formFile,
    })
}

export function getWxDayTitle(formFile) {
    return request({
        url: "/api/ajax/wxCrowd/getWxDayTitle.json",
        method: 'post',
        data: formFile,
    })
}

export function getWxHourTitle(formFile) {
    return request({
        url: "/api/ajax/wxCrowd/getWxHourTitle.json",
        method: 'post',
        data: formFile,
    })
}